import React from 'react';
import './Map.css'

const MapComponent = () => {
  return (
    <div>
     
     <iframe title="Map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15225.956885914144!2d78.4453075!3d17.4362834!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb90cf69c34c01%3A0xfcc706b56d4066a3!2sEquiverge%20Technologies!5e0!3m2!1sen!2sin!4v1700560540896!5m2!1sen!2sin" width="100%" height="520px"  style={{ border: '0' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      
    </div>
   );
 };

export default MapComponent;
