import React, { useState } from 'react';
import './goals.css';

const Goals = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  return (
    <div className="container">
      <div className="left">
        <img src="/Images/About-img/Img2.jpg" alt="Your Image" />
      </div>
      <div className="right1">
        <h5>What we are</h5>
        <h1>We are a dynamic team of creative people.</h1>
        <p>The automated process all your website tasks. Discover tools and techniques to engage effectively with vulnerable children and young people.</p>
        <button className='slide'>
          Join now for free
        </button>
      </div>
      
    </div>
  );
};

export default Goals;