import React from 'react';
import './Courses.css';
import { Link } from 'react-router-dom';

function Courses() {
  return (
    <div className='AboutUs'>
      <h1>Courses</h1>
     
      <p>
        Welcome to<strong>Equiverge_Technologies</strong> where education meets innovation! Established with a passion for empowering individuals through knowledge, we take pride in being a leading destination for comprehensive and cutting-edge training.
      </p>
    </div>
  );
}

export default Courses;