import React from 'react';
import './CoursesScrum.css';

const ManualTestingCourse = () => {
  return (
    <div className="container-Ui">
      <div className="left">
        <h2>Manual Testing</h2>
        <ul>
        <p>
          <strong>Manual Testing:</strong> Manual testing is the process of manually checking software for defects. Testers follow predefined test cases, executing them to ensure that the application behaves as expected. This approach involves documenting steps known as test cases, which define inputs, execution conditions, and expected outcomes for specific scenarios. Additionally, exploratory testing is employed, allowing testers to informally explore the application to uncover defects without predefined scripts.
        </p>
        </ul>
      </div>
      <div className="right">
        <img src="/Images/Courses-img/Mannual-Test.png" alt="Manual Testisting" />
      </div>
    </div>
  );
};

export default ManualTestingCourse;
