// App.js

import React from 'react';
import AboutUs from './AboutBody';
import ProjectDetails from './ProjectDetails';
import TestingDetails from './TestingAppli';
import Developing from './Developing';
import OutComes from './OutComes';
import MainComponent from '../Home/Team';

const MainAbout = () => {
  return (
    <div>
    <AboutUs/>
     <ProjectDetails/>
     <TestingDetails/>
     <Developing/>
     <OutComes/>
     <MainComponent/>
     
    </div>
  );
};

export default MainAbout;
