// App.js

import React from 'react';
import ContactBody from './ContactBody'; 
import ContactSection from './ContactForm';
import MapComponent from './Map';


const MainContact = () => {
  return (
    <div>
      <ContactBody/>
      <MapComponent/>
     <ContactSection/>
     

    </div>
  );
};

export default MainContact;
