// About.js (Your React Component)
import React from 'react';
import './outComes.css'; // Import your CSS file
import { LiaCheckSolid } from "react-icons/lia";

function OutComes() {
  return (
    <div>
      
    <section className="about" id="about">
      
      <div className="imageBox">
        <img src="/Images/About-img/image3.png" alt="Your Image" />
      </div>
      <div className="contentBox">
      <h2 className="custom-h2">
            Learn new skills online with top educators</h2>
        <p className="custom-p">
        The automated process all your website tasks. Discover tools and techniques to engage effectively with vulnerable children and young people.
        </p>
        <div className="icon-list">
        <div className="icon-item">
        <LiaCheckSolid/>
         <p> Techniques to engage effectively with vulnerable children and young people.</p>
        </div>
        <div className="icon-item">
        <LiaCheckSolid/>
         <p> Techniques to engage effectively with vulnerable and young people.</p>
        </div>
       
        <div className="icon-item">
        <LiaCheckSolid />
         <p> Techniques to engage effectively with vulnerable children and young people.Techniques to engage effectively .</p>
        </div>
      </div>
     
      
      </div>
    </section>
    </div>
  );
}

export default OutComes;
