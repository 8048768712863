import React from 'react';
import TeamMember from './TeamMembers';

const MainComponent = () => {
  const teamMembers = [
    {
      name: 'Susmitha',
      designation: 'ScrumMaster',
      image: '/Images/Team-imgs/Akila.png',
      socialIcons: [
        { icon: '/Images/Team-imgs/fb.png', alt: 'Facebook', link: 'https://www.facebook.com/people/Equiverge-Technologies/61550080665812/' },
        { icon: '/Images/Team-imgs/Insta1.png', alt: 'insta', link: 'https://www.instagram.com/equiverge_technologies_?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D'},
      ],
    },
    {
      name: 'Ravesh',
      designation: 'Bussiness Analyst',
      image: '/Images/Team-imgs/Team4.jpg',
      socialIcons: [
        { icon: '/Images/Team-imgs/fb.png', alt: 'Facebook', link: 'https://www.facebook.com/people/Equiverge-Technologies/61550080665812/' },
        { icon: '/Images/Team-imgs/Insta1.png', alt: 'insta', link: 'https://www.instagram.com/equiverge_technologies_?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D'},
      ],
    },
    {
      name: 'Greeshma',
      designation: 'Developer',
      image: '/Images/Team-imgs/Van1.jpeg',
      socialIcons: [
        { icon: '/Images/Team-imgs/fb.png', alt: 'Facebook', link: 'https://www.facebook.com/people/Equiverge-Technologies/61550080665812/' },
        { icon: '/Images/Team-imgs/Insta1.png', alt: 'insta', link: 'https://www.instagram.com/equiverge_technologies_?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D' },
      ],
    },
    {
      name: 'JoySon',
      designation: 'Tester',
      image: '/Images/Team-imgs/Team3.jpg',
      socialIcons: [
        { icon: '/Images/Team-imgs/fb.png', alt: 'Facebook', link: 'https://www.facebook.com/people/Equiverge-Technologies/61550080665812/' },
        { icon: '/Images/Team-imgs/Insta1.png', alt: 'intsa', link: 'https://www.instagram.com/equiverge_technologies_?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D' },
      ],
    },
    
  ];

  return (
    <div>
      <h1 className='Team'>Our Team</h1>
      {teamMembers.map((member, index) => (
        <TeamMember key={index} {...member} />
       
      ))}
    </div>
  );
};

export default MainComponent;