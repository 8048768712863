
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './Components/Home/Footer';
import Header from './Components/Home/Header';
import MainHome from './Components/Home/MainHome';
import MainCourse from './Components/Course/MainCourse';
import MainAbout from './Components/About/MainAbout';
import MainContact from './Components/Contact/MainContact';
import MainBlog from './Components/Blog/MainBlog';
import ChatContainer from './Components/Home/Msg';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<MainHome />} />
        <Route path="/course" element={<MainCourse />} />
        <Route path="/about" element={<MainAbout />} />
        <Route path="/blog" element={<MainBlog />} />
        <Route path="/contact" element={<MainContact />} /> 
        <Route path="/chat/:id" element={<ChatContainer/>} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
