import React, { useState ,useEffect} from 'react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './testimonials.css';

const Testimonals = () => {
  const testimonials = [
    {
      text: "Fantastic experience at Equiverge_Technolohies! Instructors were superb, creating a supportive and enjoyable learning environment. Highly recommended for an outstanding educational journey!",
      author: "Tharun",
      role: "Java-Developer",
    },
    {
      text: "Just finished at Equiverge_Technolohies, and I'm impressed! Instructors were experts and supportive. The positive learning atmosphere stood out. Highly satisfied!",
      author: "Phani",
      role: "ScrumMaster",
    },
    {
      text: "Equiverge_Technolohies delivers! Well-structured courses, knowledgeable instructors. Efficient learning process. Highly recommend for effective education.",
      author: "Narayana",
      role: "Tester",
    },
    {
      text: "Enrolled at Equiverge_Technolohies and it exceeded expectations. Top-notch instructors, clear explanations, and practical learning. One of the best educational experiences!",
      author: "Rakesh",
      role: "Frontend-Developer",
    },
  ];

  const [sliderSettings, setSliderSettings] = useState({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  });

  useEffect(() => {
    const handleResize = () => {
      // Adjust the settings based on the window width
      if (window.innerWidth <= 768) {
        setSliderSettings({
          ...sliderSettings,
          slidesToShow: 1,
          slidesToScroll: 1,
        });
      } else {
        setSliderSettings({
          ...sliderSettings,
          slidesToShow: 2,
          slidesToScroll: 2,
        });
      }
    };

    // Attach the event listener for window resize
    window.addEventListener('resize', handleResize);

    // Call handleResize to set initial settings
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [sliderSettings]);

  return (
    <div className="testimonial-slider mb-5">
      <div className='testimonialSlider'>
        <h6 style={{}}>Students testimonial </h6>
        <h2 style={{}} className="mt-3 content-title">Check what's our students say about us</h2>
      </div>
      <div className='containerTest'>
        <Slider {...sliderSettings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-item">
              <p className="testimonial-text">{testimonial.text}</p>
              <div className="testimonial-author">
                <h5 className="mb-0 text-capitalize" style={{ fontWeight: '600', fontSize: '1.5rem' }}>{testimonial.author}</h5>
                <p className='role-text'>{testimonial.role}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonals;