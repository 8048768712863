import React from 'react';
import './ProjectDetails.css'; // Import your CSS file

const Developing = () => {
  return (
    <div className="project-container">
      <div className="image-container">
        <img className="Img1" src="/Images/About-img/Develop-img.jpg" alt="" />
      </div>
      <div className="content-container">
        <h2>Developing </h2>

        <p>
          By joining <span>Equiverge_Technologies </span>, you can develop applications through hands-on learning. Our training programs are designed to provide practical experience with real projects, equipping you with the skills needed in the industry.
        </p>

        <p>
          Join us to work on exciting projects such as enhancing the user interface of live e-commerce websites, analyzing real business data for insights, and collaborating on mobile app development for community impact. Through these experiences, you'll gain valuable skills and problem-solving abilities that set you apart in the competitive job market.
        </p>
      </div>
    </div>
  );
};

export default Developing;
