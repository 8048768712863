import React from 'react';
import  './contact.css';


const ContactBody = () => {
  return (
    <div className='Courses'>
      <h1>Contact Us</h1>
      <h4>Don't miss the chance to be part of a vibrant and innovative academic community. Enroll now to secure your spot and embark on a journey of knowledge and growth.</h4>
      
    </div>
  );
};


export default ContactBody;
