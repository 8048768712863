import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './Msg.css';

const ChatContainer = () => {
  const { id } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [customerInfo, setCustomerInfo] = useState({ name: '', mobile: '' });
  const [showChat, setShowChat] = useState(false);

  useEffect(() => {
    const storedMessages = JSON.parse(localStorage.getItem(`chatMessages_${id}`)) || [];
    setMessages(storedMessages);
    return () => {
      localStorage.removeItem(`chatMessages_${id}`);
    };
  }, [id]);
  
  const handleUserInput = () => {
    let updatedMessages = [...messages];

    if (currentStep === 0) {
      updatedMessages.push({ sender: 'customer', text: newMessage });
      updatedMessages.push({ sender: 'bot', text: 'Hi! What\'s your name?' });
      setCurrentStep(currentStep + 1);
    } else if (currentStep === 1) {
      const updatedInfo = { ...customerInfo, name: newMessage };
      setCustomerInfo(updatedInfo);
      updatedMessages.push({ sender: 'customer', text: newMessage });
      updatedMessages.push({ sender: 'bot', text: `Hello, ${newMessage}! What's your mobile number?` });
      setCurrentStep(currentStep + 1);
    } 
    
    else {
      updatedMessages.push({ sender: 'customer', text: newMessage });
      updatedMessages.push({ sender: 'bot', text: 'Thank you for your message!  How Can I Help You?.' });
    }

    setMessages(updatedMessages);
    setNewMessage('');
    localStorage.setItem(`chatMessages_${id}`, JSON.stringify(updatedMessages));
  };

  const sendMessage = () => {
    if (newMessage) {
      handleUserInput();
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-container2">
        {showChat && (
          <div className="chat-window1">
            {messages.map((message, index) => (
              <div key={index} className={`message ${message.sender}`}>
                {message.text}
              </div>
            ))}
          </div>
        )}
        {showChat && (
          <div className="input-area">
            <input
              type="text"
              placeholder={currentStep === 0 ? 'Enter Your Name' : 'Enter Your Message'}
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
            />
            <button onClick={sendMessage}>Send</button>
          </div>
        )}
        <div
          className="chat-label"
          onClick={() => setShowChat(!showChat)}
          style={{ right: showChat ? '350px' : '50px' }}
        >
          How Can I Help You?
        </div>
      </div>
    </div>
  );
};

export default ChatContainer;
