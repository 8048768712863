import React from 'react';
import { FaPaperPlane, FaMapMarker, FaPhone, FaEnvelope, FaGithub, FaCodepen, FaTwitter, FaInstagram } from 'react-icons/fa';
import './ContactForm.css';

class ContactSection extends React.Component {
  render() {
    return (
      <section id="contact">
        <h1 className="section-header">Contact</h1>

        <div className="contact-wrapper">

          {/* Left contact page */}
          <form id="contact-form" className="form-horizontal" role="form">
            <div className="form-group">
              <div className="col-sm-12">
                <input type="text" className="form-control1" id="name" placeholder="NAME" name="name" value="" required />
              </div>
            </div>


            <div className="form-group">
              <div className="col-sm-12">
                <input type="email" className="form-control1" id="email" placeholder="EMAIL" name="email" value="" required />
              </div>
            </div>

            <div className="form-group">
    <div className="col-sm-12">
      <input type="tel" className="form-control1" id="contactNo" placeholder="CONTACT NUMBER" name="contactNo" value="" required />
    </div>
  </div>

            <textarea className="form-control2" rows="3" placeholder="MESSAGE" name="message" required></textarea>

            <button className="send-button" id="submit" type="submit" value="SEND">
              {/* <div className="alt-send-button">
                <FaPaperPlane /><span className="send-text">SEND</span>
              </div> */}Send
            </button>
          </form>

          {/* Right contact page */}

          <div className="direct-contact-container">
            <ul className="contact-list">
              <li className="list-item"><FaMapMarker size={28} /><span className="contact-text place">#104,Everest Block C Aditya Enclave Maitrivanam Hyderabad-500038</span></li>
              <li className="list-item"><FaPhone  size={28}/><span className="contact-text phone"><a href="tel:1-212-555-5555" title="Give me a call">+91 6303331284, 7731092139, 040-66661248</a></span></li>
              <li className="list-item"><FaEnvelope  size={28}/><span className="contact-text gmail"><a href="mailto:#" title="Send me an email">info@equivergetech.com</a></span></li>
            </ul>

            <hr />

            <ul className="social-media-list">
              <li><a href="#" target="_blank" className="contact-icon"><FaGithub /></a></li>
              <li><a href="#" target="_blank" className="contact-icon"><FaCodepen /></a></li>
              <li><a href="#" target="_blank" className="contact-icon"><FaTwitter /></a></li>
              <li><a href="#" target="_blank" className="contact-icon"><FaInstagram /></a></li>
            </ul>

            <hr />

            <div className="copyright">&copy; ALL OF THE RIGHTS RESERVED</div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactSection;