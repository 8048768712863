import React from 'react';
import './Ba.css';

const BACourse = () => {
  return (
    <div className="container-Ba">
        <div className="left">
        <img src="/Images/Courses-img/BA-removebg-preview.png" alt="Your Image" />
      </div>
      <div className="right">
        <h2><strong> Business Analyst</strong></h2>
        <ul>
         <p><strong>Requirements Gathering:</strong>Business Analysts elicit, analyze, and document requirements from stakeholders to understand their needs and expectations thoroughly.
        Process Modeling They visualize business processes using tools like flowcharts and diagrams to identify inefficiencies and propose improvements.
         Data Analysis Business Analysts analyze data to uncover trends, patterns, and insights that can inform business decisions.
           </p>
        </ul>
      </div>
      
    </div>
  );
};

export default BACourse;
