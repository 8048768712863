// App.js

import React from 'react';

import BlogSection1 from './BlogSection1';
import BlogBody from './BlogBody';

const MainBlog = () => {
  return (
    <div>
      <BlogBody/>
     <BlogSection1/>
    </div>
  );
};

export default MainBlog;
