// App.js

import React from 'react';
// Assuming the ContactBody component is in the same directory
import Home1 from './HomeBody';
import Stats from './Stats';
import BackgroundComponent from './Grow';
import Goals from './Goals';
import Skills from './Skills';
import MainComponent from './Team';
import Course1 from './Intern';
import Testimonals from './Testimonials';

const MainHome = () => {
  return (
    <div>
      <Home1/>

<Stats/>
<BackgroundComponent/>

<Goals/>
<Skills/>
<MainComponent/>
<Course1/>

<Testimonals/>
    </div>
  );
};

export default MainHome;
