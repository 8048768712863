import React from 'react';
import './JavaCourse.css';

const JavaCourse = () => {
  return (
    <div className="container-java">
      <div className="left">
        <img src="/Images/Courses-img/Java-removebg-preview.png" alt="Your Image" />
      </div>
      <div className="right">
        <h1>Java</h1>
        <ul>
          <p><strong>Java:</strong> Being an object-oriented, class-based language, Java provides strong support for building robust and scalable back-end systems.
          Spring Framework: Spring is a comprehensive framework that simplifies Java development and promotes good design practices. It includes modules for security, data access, and more.
          RESTful APIs: Representational State Transfer APIs are essential for communication between the front-end and back-end of web applications. They enable seamless data exchange over HTTP.
         </p>
        </ul>
      </div>
    </div>
  );
};

export default JavaCourse;
