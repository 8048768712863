import React from 'react';
import './Blog.css'; 
import { CgProfile } from 'react-icons/cg';
import { AiFillMessage } from 'react-icons/ai';
import { BsSearchHeartFill } from 'react-icons/bs';
 // Import Font Awesome CSS

const BlogSection1 = () => {
  return (
    <div className="sb-container">
      {/* Blog Header */}
     

      {/* Blog Contents */}
      <div className="blog-container">
        <div className="blog-left">
          {/* Blog Posts */}
          <div className="blog-card">
         
             <img src="/Images/Blog-img/Blog1-img.jpg" alt=""width="750px"height="370px"/>
           
            <h5>Group Discussion  session</h5>
            
            <p className='para1'>"Our institute organizes dynamic group discussion sessions, fostering interactive learning environments where diverse ideas collide and innovative solutions emerge. Participants engage in lively debates, honing communication skills, enhancing teamwork, and gaining valuable insights, preparing them for success in today's collaborative professional world..."</p>
            <p style={{padding:'3px'}}><CgProfile/>Travel LifeStyle | <AiFillMessage/> 03 comments</p>
          </div>
          <div className="blog-card">
         
             <img src="/Images/Blog-img/Blog2-img.jpg" alt=""style={{objectFit:'cover'}} width="750px"height="370px"/>
           
            <h5>Project For a Group Members</h5>
            
            <p className='para1'>"Assign projects by aligning individual strengths, fostering clear communication, and encouraging collaboration. This approach optimizes creativity, efficiency, and overall group success, ensuring a cohesive and productive effort."</p>
            <p className="Hov"style={{padding:'3px'}}><CgProfile/>Travel LifeStyle | <AiFillMessage/> 03 comments</p>
          </div>


          <div className="blog-card">
         
             <img src="/Images/Blog-img/Blog3-img.png" alt=""style={{objectFit:'cover'}} width="750px"height="370px"/>
           
            <h5>Training Session</h5>
            
            <p className='para1'>"Experience an immersive training session tailored to your needs, where knowledge meets action. Our expert trainers guide you through hands-on exercises, empowering you with practical skills and confidence for real-world success."</p>
            <p className="Hov" style={{padding:'3px'}}><CgProfile/>Travel LifeStyle | <AiFillMessage/> 03 comments</p>
          </div>

          <div className="blog-card">
         
             <img src="/Images/Blog-img/Blog4-img.jpg" alt=""style={{objectFit:'cover'}} width="750px"height="370px"/>
           
            <h5>Certfication</h5>
            
            <p className='para1'>"Certifications from reputable institutes serve proof of your expertise, enhancing your credibility and opening doors to new opportunities. Our institute offers industry-recognized certifications, validating your skills and knowledge to excel in your chosen field."




          </p>
            <p className="Hov" style={{padding:'3px'}}><CgProfile/>Travel LifeStyle | <AiFillMessage/> 03 comments</p>
          </div>
         
        </div>

        

        <div className="blog-right">
          {/* About Me */}
          <div className="blog-card2">
          <div>
      <h1>Courses Available</h1>
      <hr />
      <ul>
       <p>---Frontend</p>
        <hr />
       <p>---JavaFullStack</p> <hr />
       <p>---ScrumMaster</p> <hr />
       <p>---Testing</p> <hr />
       <p>---Dot_Net</p><hr />
       <p>---BA</p>
      </ul>
      </div>
          </div>


          



          <div className="blog-card2">
          <h4>Sessions Conducted</h4>

<div className='btns'>
  <a href>Coding</a>
  <a href>Assignment</a>
  <a href>GroupDiscussion</a>
  <a href>Grooming</a>
  <a href>Training</a>
  <a href>Mock-interview</a>
  
 
  
</div>
     
    
          </div>

          <div className="blog-card2">
      <h4>Sessions Conducted</h4>

      <div className='image-content'>

        <img src="https://i0.wp.com/www.freshers360.com/wp-content/uploads/2016/04/25d24c07-2647-4615-9e15-a7cdff6ba818fca6c110-ae85-4ff1-b25f-5ecd6d7e1b39.jpg?resize=277%2C189" alt="Mock Interview" />
        <h1>Mock Interview</h1>
        <p>
          Join our mock interview sessions to enhance your interview skills and
          boost your confidence. Our experienced mentors provide valuable feedback
          to help you succeed in real interviews.
        </p>
      </div>
    </div>

          {/* Social Media */}
          <div className="blog-card1">
            <h3>Follow Me</h3>
            <div className="image-row">
      <h1>Heading</h1>
      <div className="image-containe">
        <img src="/Images/Blog-img/Blog5-img.jpg" alt="Image 1" />
        <img src="/Images/Blog-img/Blog6-img.webp" alt="Image 2" />
        <img src="/Images/Blog-img/Blog7-img.jpeg" alt="Image 3" />
        <img src="/Images/Blog-img/Blog8-img.jpg" alt="Image 4" />
        <img src="/Images/Blog-img/Blog9-img.jpg" alt="Image 5" />
        <img src="/Images/Blog-img/Blog10-img.jpg" alt="Image 6" />
      </div>
    </div>
          </div>

          <div className="blog-card1">
          
    <h3 id="reply-title" className="comment-reply-title">
      Leave a Reply <small><a rel="nofollow" id="cancel-comment-reply-link" href="/hello-world/#respond" style={{ display: 'none' }}>Cancel reply</a></small>
    </h3>
    <form action="http://cave-theme.local/wp-comments-post.php" method="post" id="commentform" className="comment-form" noValidate>
      <p className="comment-notes">
        <span id="email-notes">Your email address will not be published.</span> Required fields are marked <span className="required">*</span>
      </p>
      <p className="comment-form-comment1">
        <label htmlFor="comment">Comment</label>
        <textarea className="text-area1" id="comment" name="comment" cols="45" rows="8" maxLength="65" required />
      </p>
      <p className="comment-form-author">
        <label htmlFor="author">Name <span className="required">*</span></label>
        <input id="author" name="author" type="text" size="30" maxLength="245" required />
      </p>
      <p className="comment-form-email">
        <label htmlFor="email">Email <span className="required">*</span></label>
        <input id="email" name="email" type="email" size="30" maxLength="100" aria-describedby="email-notes" required />
      </p>
     
      <p className="comment-form-cookies-consent">
        <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes" defaultChecked />
        <label htmlFor="wp-comment-cookies-consent">Save my name, email, and website in this browser for the next time I comment.</label>
      </p>
      <p className="form-submit">
        <input name="submit" type="submit" id="submit" className="submit" value="Post Comment" />
        <input type="hidden" name="comment_post_ID" value="1" id="comment_post_ID" />
        <input type="hidden" name="comment_parent" id="comment_parent" value="0" />
      </p>
    </form>
  
          </div>
        </div>
      </div>

     
     
    </div>
  );
};

export default BlogSection1;
